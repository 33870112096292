import { render, staticRenderFns } from "./ChangePasswordForm.vue?vue&type=template&id=fce95db2&scoped=true&"
import script from "./ChangePasswordForm.vue?vue&type=script&lang=ts&"
export * from "./ChangePasswordForm.vue?vue&type=script&lang=ts&"
import style0 from "../../../assets/styles/reset-password/ChangePasswordForm.scss?vue&type=style&index=0&id=fce95db2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fce95db2",
  null
  
)

export default component.exports