






















































































































































































import { Component, Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import {
  BForm, BFormInput, BIconInfoCircle, BIconLock, BPopover,
} from 'bootstrap-vue';
import { namespace } from 'vuex-class';
import InputText from '@/components/InputText.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const accountStore = namespace('AccountStore');

@Component({
  components: {
    ButtonComponent,
    InputText,
    BForm,
    BFormInput,
    BIconLock,
    BPopover,
    BIconInfoCircle,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        valid(value: string): boolean {
          const containsUppercase = value ? value.match(/[A-Z]/) : false;
          const containsLowercase = value ? value.match(/[a-z]/) : false;
          const containsNumber = value ? value.match(/[0-9]/) : false;
          return !!containsUppercase && !!containsLowercase && !!containsNumber;
        },
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
})
export default class ChangePasswordForm extends BreakpointWrapper {
  @Prop({
    required: true,
    default: '',
  })
  token!: string;

  @accountStore.Action
  private checkActivatability!: (
      payload: {
        token: string;
        email: string | null;
        password: string | null;
      },
  ) => Promise<Record<string, boolean>>;

  private form = {
    password: '',
    repeatPassword: '',
  };

  private passwordFieldFocused = false;

  private passwordFieldIsDirty = false;

  private repeatPasswordFieldFocused = false;

  private repeatPasswordFieldIsDirty = false;

  private tokenUserUid = '';

  private tokenUserEmail = '';

  private get isUppercase(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[A-Z]/) : false;
  }

  private get isLowercase(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[a-z]/) : false;
  }

  private get hasNumber(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[0-9]/) : false;
  }

  mounted(): void {
    if (this.token) {
      const parsedToken = this.parseJwt(this.token);
      let userEmail = '';
      let userUid = '';
      if (parsedToken) {
        userEmail = parsedToken.e ? parsedToken.e : '';
        userUid = parsedToken.u ? parsedToken.u : '';
      }
      this.tokenUserEmail = userEmail;
      this.tokenUserUid = userUid;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseJwt(token: string): { c: string; e: string; exp: number; iat: number; u: string } | null {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+')
        .replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map((c) => `%${(`00${c.charCodeAt(0)
          .toString(16)}`).slice(-2)}`)
        .join(''));

      return JSON.parse(jsonPayload);
    }
    return null;
  }

  private validateState(name: string): boolean {
    const $dirty = this.$v.form[name]?.$dirty;
    const $error = this.$v.form[name]?.$error;
    return $dirty ? !$error : true;
  }

  private onBlur(fieldType: string): void {
    const $error = this.$v.form[fieldType]?.$error;
    const $dirty = this.$v.form[fieldType]?.$dirty;
    if ($dirty) {
      switch (fieldType) {
        case 'password':
          this.passwordFieldFocused = false;
          this.passwordFieldIsDirty = !!$error;
          break;
        case 'repeatPassword':
          this.repeatPasswordFieldFocused = false;
          this.repeatPasswordFieldIsDirty = !!$error;
          break;
        default:
          break;
      }
    }
  }

  private checkEmptyForm(fieldType: string): void {
    const $invalid = this.$v.form[fieldType]?.$invalid;
    const $required = this.$v.form[fieldType]?.required;
    if ($invalid) {
      switch (fieldType) {
        case 'password':
          this.passwordFieldIsDirty = !$required;
          break;
        case 'repeatPassword':
          this.repeatPasswordFieldIsDirty = !$required;
          break;
        default:
          break;
      }
    }
  }

  private onSubmit(): void {
    if (this.$v.$invalid) {
      this.checkEmptyForm('password');
      this.checkEmptyForm('repeatPassword');
    } else {
      this.checkActivatability({
        token: this.token,
        email: null,
        password: this.form.password,
      })
        .then((responseData) => {
          if (responseData.success) {
            this.$emit('reset-password-success');
          }
        })
        .catch();
    }
  }

  private disabledSubmit(): boolean {
    return this.$v.$invalid;
  }
}
